<template>
  <div class="cont-wrap">
		<div class="inner">
      <Empty v-if="getInvitationList.length===0"/>
      <NotEmpty v-else/>
			
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import EventBus from "@/utils/CommonEventBus";
import Empty from "@/components/mainview/Empty.vue";
import NotEmpty from "@/components/mainview/NotEmpty.vue";
export default {
  name: 'MainView',
  components: {Empty,NotEmpty},
  computed:{
      ...mapGetters(['getInvitationList']),
  },
  data(){
    return {
    }
  },
  
  created(){
    this.getData()
    const context = this
    EventBus.$on("waterMarkSucess", (param) => {
      context.getData()
    });
  },
  mounted(){
    
  },
  beforeDestroy(){
    this.$store.commit('REQUEST_INVITATION_LIST')
    EventBus.$off('waterMarkSucess')
  },
  methods: {
    getData(){
      const parameter ={
      }
      this.$store.dispatch("REQUEST_INVITATION_LIST",parameter)

    }

  },

}
</script>
