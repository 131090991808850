<template>
	<div class="product-list-page">
		<p class="title"><span>{{getUserInfo.name}}</span>님의 제작 내역</p>
		<div class="product-list">
			<div :class="['list',previewPossibleFlag(invitation)?'':'done']" v-for="(invitation,index) in getInvitationList" :key="index">
				<div class="txt-div" v-if="invitation.purchase === null">
					<div class="date-txt">
						<p class="tit">미리보기 유효기간</p>
						<p class="date">{{previewPossibleDate(invitation.registDt)}} 까지</p>
					</div>
				</div>
				<div class="txt-div" v-else>
					<div class="date-txt">
						<p class="tit">구매일</p>
						<p class="date">{{getYYYYMMDDTime(invitation.purchase)}}</p>
					</div>
					<div class="date-txt">
						<p class="tit">URL 유효기간</p>
						<p class="date">{{getYYYYMMDDTime(invitation.invitationTimeDatetime)}} 까지</p>
					</div>
				</div>
				<div class="img-div">
					<div class="info-txt warning" v-if="invitation.purchase === null && previewPossibleFlag(invitation)">
						<p class="info"><span>워터마크 미제거시 <br />미리보기 청첩장은 7일 후 삭제됩니다.</span></p>
						<a href="javascript:" @click="clickWaterMarkDelete(invitation.invitationId)" class="black-btn"><span>워터마크 제거</span></a>
					</div>
					<div class="info-txt" v-else-if="dateEndFlag(invitation)">
						<p class="info"><span>예식일이 지나 수정이 불가능한 청첩장입니다.</span></p>
					</div>
					<div class="info-txt" v-else-if="dateEnd30Flag(invitation)">
						<p class="info"><span>예식 후 30일이 지나 호스팅이 종료된 청첩장입니다.</span></p>
					</div>
					<p class="img">
						<img v-if="invitation.invitationMainImageFile ===null" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
						<img v-else :src="makeDisplayUrl(invitation.invitationMainImageFile.fileSavePath)" alt="">
					</p>
					<a href="javascript:" @click="clickAttendResultPopup(invitation)" v-if="invitation.purchase !== null && invitation.invitationAttendYn === 'Y'" class="result-btn"><span>참석의사 집계결과</span></a>
				</div>
				<div class="btn-div">
					<a href="javascript:" @click="clickInvitationModify(invitation)" :class="['black-btn',canModifyClass(invitation)]"><span>수정하기</span></a>
					<a href="javascript:" @click="clickInvitationCopyLink(invitation)" :class="['white-btn',canLinkCopyClass(invitation)]" ><span>링크 복사</span></a>
				</div>
			</div>
			
		</div>
		<div class="inquiry-div">
			<div class="inquiry"><a href="javascript:">모바일청첩장 제작 문의</a></div>
			<div class="inquiry">
				<a href="javascript:" class="more" @click="popupQuestion"><span>1:1 문의</span></a>
				<a href="tel:070-8983-7401" class="num"><span>070-8983-7401</span></a>
			</div>
		</div>
	</div>
</template>


<script>
import { mapGetters } from "vuex";
import EventBus from "@/utils/CommonEventBus";

export default {
  name: 'NotEmpty',
  components: {},
  computed:{
      ...mapGetters(['getUserInfo','getInvitationList']),
  },
  data(){
    return {
    }
  },
  created(){
    
  },
  mounted(){
  },
  methods: {
	clickInvitationModify(invitation){
		if(this.canModifyClass(invitation)=== 'disabled'){
			return;
		}
		
        this.$router.push({
          path: '/write',
          query:{invitationId:invitation.invitationId}
		})
	},
	clickInvitationCopyLink(invitation){
		if(this.canLinkCopyClass(invitation)=== 'disabled'){
			return;
		}
    	this.clickCopyTxt(invitation.invitationAesPath);
		
		

	},
	clickWaterMarkDelete(invitationId){
		
		const param = {
			flag: 'waterMarkDeletePop',
			invitationId:invitationId
		}
		EventBus.$emit('layerPopup',param)
	},
	clickAttendResultPopup(invitation){
      const param = {
        flag: 'attendResultPop',
        invitation: invitation
      }
      EventBus.$emit('layerPopup',param)
	},
	canModifyClass(invitation){
		if(this.dateEndFlag(invitation)){
			return 'disabled'
		}
		if(this.dateEnd30Flag(invitation)){
			return 'disabled'
		}

		return ''
	},
	canLinkCopyClass(invitation){
		if(this.dateEnd30Flag(invitation)){
			return 'disabled'
		}

		return ''

	},
	popupQuestion(){
		window.open('http://pf.kakao.com/_xcKMaG')
	}

  },

}
</script>
