<template>
	<div class="product-list-page">
		<p class="title"><span>{{getUserInfo.name}}</span>님의 제작 내역</p>
		<div class="product-list">
			<div class="no-list-area">
				<div class="no-list-div">
					<p class="no-list-txt">제작 내역이 없습니다.</p>
					<div class="btn-area">
						<a href="javascript:" class="black-btn"  @click="$router.push('/write')"><span>청첩장 제작하기</span></a>
						<a href="javascript:" class="white-btn"  @click="popupPurchase"><span>청첩장 구매하기</span></a>
					</div>
				</div>
			</div>
		</div>
		<div class="inquiry-div">
			<div class="inquiry"><a href="javascript:">모바일청첩장 제작 문의</a></div>
			<div class="inquiry">
				<a href="javascript:"  @click="popupQuestion" class="more"><span>1:1 문의</span></a>
				<a href="tel:070-8983-7401" class="num"><span>070-8983-7401</span></a>
			</div>
		</div>
	</div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  name: 'Empty',
  components: {},
  computed:{
      ...mapGetters(['getUserInfo']),
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {

	popupQuestion(){
		window.open('http://pf.kakao.com/_xcKMaG')
	},
	popupPurchase(){
		window.open('https://smartstore.naver.com/vividmare')
	}
	
  },

}
</script>
